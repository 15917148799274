import React, { Fragment } from 'react';
import { bool } from 'prop-types';
import LocationSection from '@components/LocationSection';
import WeddingInfoBox from './WeddingInfoBox';
import ButtonLive from './ButtonLive';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {

  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="godName">Sairam</span>
              <h2 className="main-font main-font__wedding">Marriage Ceremony and Wedding Celebration</h2>
              {/* <a className="sub-title__wedding" target="_blank" href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MW5mZG9uYWVlZG5rZjQwazh0YXJrOGdnazYgZmFtaWx5MTI4MTI1NzU5MjIzMDg3MDg1MDVAZw&tmsrc=family12812575922308708505%40group.calendar.google.com"><img border="0" src="https://www.google.com/calendar/images/ext/gc_button1_en.gif" /></a> */}
             
              <a className="sub-title__wedding" target="_blank" href="https://www.addevent.com/event/Hk15670546+google">Add to Calendar &nbsp;</a> &nbsp;
             {/* <a className="sub-title__wedding" target="_blank" href="https://www.addevent.com/event/Hk15670546+apple">Apple</a> &nbsp;
              <a className="sub-title__wedding" target="_blank" href="https://www.addevent.com/event/Hk15670546+yahoo">Yahoo</a>  &nbsp;
              <a className="sub-title__wedding" target="_blank" href="https://www.addevent.com/event/Hk15670546+office365">Office 365</a> &nbsp;
              <a className="sub-title__wedding" target="_blank" href="https://www.addevent.com/event/Hk15670546+outlook">Outlook</a> */}
        
        </div>
       
          </div>
          <div className="row">
            {!isInvitation && (
              <div className="col-md-10 col-md-offset-1">
                <WeddingInfoBox
                  title="Reception"
                  time="7:00PM onwards"
                  date="Thursday, 26th January 2023"
                  description="S.R.R. Kalyana Mantapa <br/> No:84, 1, B.Channasandra Main Rd, next to Chaithanya School, OMBR Layout, Banaswadi, Bengaluru, Karnataka 560043"
                  lunch="Dinner: 7.30PM to 10:00PM"
                />
                <WeddingInfoBox
                  title="Muhurtham"
                  time="6:00AM - 07:30AM"
                  date="Friday, 27th January 2023"
                  description="S.R.R. Kalyana Mantapa <br/> No:84, 1, B.Channasandra Main Rd, next to Chaithanya School, OMBR Layout, Banaswadi, Bengaluru, Karnataka 560043"
                  lunch="BreakFast: 7.30AM to 9.30AM"
                />
              </div>
            )}
          </div>

         
        </div>
       <div className="col-md-8 col-md-offset-2 text-center fh5co-heading"> <h2 className="main-font main-font__wedding">Yours presence  will bring us so much joy.</h2></div>
       
      </div>
      <LocationSection />
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
