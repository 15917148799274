/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MW5mZG9uYWVlZG5rZjQwazh0YXJrOGdnazYgZmFtaWx5MTI4MTI1NzU5MjIzMDg3MDg1MDVAZw&tmsrc=family12812575922308708505%40group.calendar.google.com`;
export const GOOGLE_MAPS_LINK = `https://www.google.com/maps/dir//srr+kalyana+mantapa/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bae112cc32519c9:0xcf4f8dd8b1d002cd?sa=X&ved=2ahUKEwjbx-T4itP7AhWrA7cAHdqMDywQ9Rd6BAhiEAM`;

/**
 * Wedding time
 */
export const EPOCH_START_EVENT = 1674739800;
export const EPOCH_END_EVENT = 1674784800;
export const UTC_WEDDING_TIME = '2023-01-27:06:00:00Z';
